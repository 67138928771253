import { GTM_CLICK_AGENT_PROFILE_FACEBOOK, GTM_CLICK_AGENT_PROFILE_INSTAGRAM, 
  GTM_CLICK_AGENT_PROFILE_LINKEDIN, GTM_CLICK_AGENT_PROFILE_TIKTOK, GTM_CLICK_AGENT_PROFILE_TWITTER, 
  GTM_CLICK_AGENT_PROFILE_WEBSITE, GTM_CLICK_AGENT_PROFILE_YOUTUBE } from './events';

export enum AgentSocialMediaLinks {
  WEBSITE = 'website',
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  TIKTOK = 'tiktok',
}

export const PROFILE_LINKS_TO_GTM_EVENTS = {
  website: GTM_CLICK_AGENT_PROFILE_WEBSITE,
  facebook: GTM_CLICK_AGENT_PROFILE_FACEBOOK,
  linkedin: GTM_CLICK_AGENT_PROFILE_LINKEDIN,
  instagram: GTM_CLICK_AGENT_PROFILE_INSTAGRAM,
  twitter: GTM_CLICK_AGENT_PROFILE_TWITTER,
  youtube: GTM_CLICK_AGENT_PROFILE_YOUTUBE,
  tiktok: GTM_CLICK_AGENT_PROFILE_TIKTOK,
};
