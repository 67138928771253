/**
 * Finds and returns the first UUID found within the last string of an array of strings.
 *
 * @param dynamic - An array of strings to search for a UUID.
 * @returns The first UUID found in the last string of the array, or an empty string if no UUID is found.
 */
export function findUUIDWithinStrings(dynamic: string[]): string {
  const defaultUUID = '';
  const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;
  if (!dynamic.length) return defaultUUID;
  const lastString = dynamic[dynamic.length - 1];
  const match = lastString.match(uuidRegex);
  return match ? match[0] : defaultUUID;
}