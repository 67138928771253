import React, { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import styles from './style.module.scss';
import { buildClassName } from 'utils/build-class-name';
import Tooltip from 'components/control/tooltip';
import { useIsMobile } from 'hooks/use-size-class';
import { trackEvent } from 'utils/google-tag-manager';

interface Props {
  className?: string;
  content: string;
  tooltipText?: string;
  onClick?: () => void;
}

function CopyButton({ className, content, tooltipText, onClick }: Props) {
  const [isTooltipActive, setIsTooltipActive] = useState(false);
  const isMobile = useIsMobile();

  const handleCopy = (content: string) => {
    if (onClick) onClick();
    navigator.clipboard.writeText(content).then(() => {
      if (!isMobile) {
        setIsTooltipActive(true);
        setTimeout(() => {
          setIsTooltipActive(false);
        }, 2000);
      }
    });
  };

  return (
    <a onClick={() => handleCopy(content)}>
      <div className={buildClassName(styles.component, className)}>
        <ContentCopyIcon className={styles.icon} />
        <Tooltip content={tooltipText || 'Link Copied'} className={styles['custom-tooltip-position']} isActive={isTooltipActive} />
      </div>
    </a>
  );
}

export default CopyButton;